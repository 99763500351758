import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlinePaperClip,
  AiFillHdd,
} from "react-icons/ai";
import { FaBusinessTime, FaBlogger, FaPhotoVideo } from "react-icons/fa";
import { BiMenu, BiPodcast, BiSolidBusiness } from "react-icons/bi";
// import { Outlet } from "react-router-dom";
import { IoIosContact } from "react-icons/io";
import { FaBoltLightning } from "react-icons/fa6";
import { Layout, Menu } from "antd";
import { logoutAccess } from "../../store/stateFunctions";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { GrUserPolice } from "react-icons/gr";
import { FaTrafficLight } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
const { Sider, Content } = Layout;

const SuperAdminSidebar = ({ role }) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div>
      <div className="row bg-primary p-3">
        <div className="col-md-2">
          <a
            className="bg-white p-2 rounded-3 border border-bottom border-2 border-dark shadow"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
            id="side"
          >
            <BiMenu style={{ color: "black", fontSize: 25 }} />
          </a>
        </div>
        <div className="col-md-8 text-center text-white">
          <h4>Welcome To The Ahamadnagar Municipal Corporation, Ahamadnagar</h4>
        </div>
        <div className="col-md-2 text-end">
          <Button
            variant="contained"
            color="error"
            className=" fw-bold"
            onClick={() => logoutAccess("Super Admin")}
          >
            Logout
          </Button>
        </div>
      </div>
      <ul className="nav">
        <li className="nav-item" id="sidebarm">
          <div
            className="offcanvas offcanvas-start"
            style={{ width: "20rem" }}
            tabIndex={-1}
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header bg-primary text-white ">
              <p id="size" className="fs-4">
                DMC Department
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body ">
              <Sider width={300} style={{ height: "100vh" }}>
                <div style={{ height: "100%" }}>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["dashboard"]}
                    onClick={({ key }) => {
                      if (key === "signout") {
                      } else {
                        navigate(key);
                      }
                    }}
                    items={[
                      {
                        key: "dashboard",
                        icon: <AiOutlineDashboard className="fs-4" />,
                        label: "Dashboard",
                      },

                      {
                        key: "Applications",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: "DMC Applications",
                        children: [
                          {
                            key: "totalapplication",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectedapplication",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingapplication",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                          {
                            key: "totalmurticapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Murtikar Applications",
                          },
                        ],
                      },

                      {
                        key: "Nagar Rachana Department",
                        icon: <FaUserShield className="fs-4" />,
                        label: "Nagar Rachana Department",
                        children: [
                          {
                            key: "nagardashboard",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Dashboard",
                          },
                          {
                            key: "totalnagar",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvednagar",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectednagar",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingnagar",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },

                      {
                        key: "Divisional Department",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: "Divisional User",
                        children: [
                          {
                            key: "divisiondashboard",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Dashboard",
                          },
                          {
                            key: "totaldivision",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approveddivision",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejecteddivision",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingdivision",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },

                      {
                        key: "Police Department",
                        icon: <GrUserPolice className="fs-4" />,
                        label: "Police Department",
                        children: [
                          {
                            key: "policedashboard",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Dashboard",
                          },
                          {
                            key: "totalpolice",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvedpolice",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectedpolice",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingpolice",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },

                      {
                        key: "Traffic Police Department",
                        icon: <FaTrafficLight className="fs-4" />,
                        label: "Traffic Police Department",
                        children: [
                          {
                            key: "trafficdashboard",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Dashboard",
                          },
                          {
                            key: "totaltraffic",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvedtraffic",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectedtraffic",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingtraffic",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },

                      {
                        key: "Fire Department",
                        icon: <FaUserShield className="fs-4" />,
                        label: "Fire Department",
                        children: [
                          {
                            key: "firedashboard",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Dashboard",
                          },
                          {
                            key: "totalfire",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvedfire",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectedfire",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingfire",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },

                      {
                        key: "MSEDCL Department",
                        icon: <FaUserShield className="fs-4" />,
                        label: "MSEDCL Department",
                        children: [
                          {
                            key: "pwddashboard",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Dashboard",
                          },
                          {
                            key: "totalpwd",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvedpwd",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectedpwd",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingpwd",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </Sider>
            </div>
          </div>
        </li>
      </ul>

      <Content
        style={{
          // margin: "24px 16px",
          padding: 22,
          // minHeight: 280,
        }}
      >
        {/* <Outlet /> */}
      </Content>
    </div>
  );
};

export default SuperAdminSidebar;

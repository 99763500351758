import React, { useState } from "react";
import { server } from "../../../common";
import { useSelectAccess } from "../../../store/stateFunctions";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { AxiosError } from "axios";
import MurticarUserApplicationModal from "./MurticarUserApplicationModal";

const MurticarTotalApptions = ({ role }) => {
  const [data, setData] = useState([]);
  const user = useSelectAccess(role);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleShow = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    getData();
  }, []);

  // Get
  const getData = () => {
    server
      .get("/murticar/getallappl", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response.data.error);
        }
      });
  };

  // search
  const SearchData = (searchValue) => {
    server
      .get(`/murticar/searchmurtikarbyid?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className="container mt-4">
        <div className="card mb-3">
          <div className="card-header">Search Application</div>
          <div className="card-body row">
            <div className="form-group col-md-5">
              <label>अँप्लिकेशन नंबर :</label>
              <input
                type="search"
                className="form-control"
                placeholder="Search "
                onChange={(e) => SearchData(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className=" mt-4">
          <div className="card mb-3">
            <div className="card-header">Total Application</div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr.No.</th>
                      <th>Application No.</th>
                      <th>Application Date/Time</th>
                      <th>Applicant</th>
                      <th>Applicant Name</th>
                      <th>Address</th>
                      <th>Contact No.</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.id}</td>
                            <td>{item.created_at}</td>
                            <td>{item.applicant}</td>
                            <td>{item.name}</td>
                            <td>{item.address}</td>
                            <td>{item.mobile}</td>

                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleShow(item.id)}
                              >
                                View
                              </button>
                              {/* <button className="btn btn-info btn-sm my-2">
                          Approve
                        </button>
                        <button className="btn btn-danger btn-sm my-2">
                          Reject
                        </button> */}
                            </td>
                          </tr>
                          {/* Add more rows here */}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MurticarUserApplicationModal
        show={showModal}
        handleClose={handleClose}
        id={selectedId}
        role={role}
      />
    </>
  );
};

export default MurticarTotalApptions;

import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { server } from "../../../common";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../../store/stateFunctions";
import { AxiosError } from "axios";

function MurticarUserApplicationModal({ show, handleClose, id, role }) {
  const [data, setData] = useState(null);
  const user = useSelectAccess(role);
  const fileInputRef = useRef();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [showfileupload, setShowfileupload] = useState(false);

  const handleClosefile = () => setShowfileupload(false);
  const handleShowfile = () => setShowfileupload(true);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  };

  useEffect(() => {
    if (show && id) {
      getData(id);
    }
  }, [show, id]);

  const getData = (applicationId) => {
    server
      .get(`/murticar/murticarapplication/${applicationId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("API response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  const AddProfilePicture = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("police_NOC", fileInputRef.current.files[0]);
    try {
      server.post(`/user_appl/updateapplication/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.authToken,
        },
      });
      toast.success("File Uploaded successfully");
      handleClosefile();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
        console.log(error.message);
      }
    }
  };

  // Update Status function
  const updateStatus = async (status) => {
    try {
      await server.post(
        `/user_appl/updateapplication/${id}`,
        { permission_status: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.authToken,
          },
        }
      );
      toast.success(`Permission Status ${status} successfully`);
      getData(id); // Reload data after status update
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {data
              ? `Application Details (Application No. ${data.id})`
              : "Application Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="4">Application Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fw-bold">Applicant Id</td>
                    <td>{data.id}</td>
                    <td className="fw-bold">Contact</td>
                    <td>{data.mobile}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Applicant Name</td>
                    <td>{data.name}</td>
                    <td className="fw-bold">Email ID</td>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Address</td>
                    <td>{data.address}</td>
                    <td className="fw-bold">Mandal Name</td>
                    <td>{data.mandal_name}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">amc_divisional_office</td>
                    <td>{data.amc_divisional_office}</td>
                    <td className="fw-bold">appl_type</td>
                    <td>{data.appl_type}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">applicant</td>
                    <td>{data.applicant}</td>
                    <td className="fw-bold">fire_station</td>
                    <td>{data.fire_station}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">murti_type</td>
                    <td>{data.murti_type}</td>
                    <td className="fw-bold">place</td>
                    <td>{data.place}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">place_height</td>
                    <td>{data.place_height}</td>
                    <td className="fw-bold">place_type</td>
                    <td>{data.place_type}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">registration</td>
                    <td>{data.registration}</td>
                    <td className="fw-bold">shop_adderess</td>
                    <td>{data.shop_adderess}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">shop_name</td>
                    <td>{data.shop_name}</td>
                    <td className="fw-bold">shop_owner_name</td>
                    <td>{data.shop_owner_name}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">start_date</td>
                    <td>{data.start_date}</td>
                    <td className="fw-bold">traffice_office</td>
                    <td>{data.traffice_office}</td>
                  </tr>
                </tbody>
              </Table>

              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="5" className="text-dark">
                      Document Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>आधार कार्ड</td>
                    <td>
                      <a
                        href={data.adharcard}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>हमीपत्र (मूर्तिकार / स्टॉकिस्ट अर्ज प्रकार नुसार)</td>
                    <td>
                      <a
                        href={data.affidavit_type}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>जागा मालकाची NOC</td>
                    <td>
                      <a
                        href={data.place_owner_noc}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>मालमत्ता कर पावती (property tax receipt)</td>
                    <td>
                      <a
                        href={data.property_tax_receipt}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            "Loading..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for file upload */}
      <Modal show={showfileupload} onHide={handleClosefile}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Police NOC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
            accept="application/pdf, image/jpeg, image/png"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosefile}>
            Cancel
          </Button>
          <Button variant="primary" onClick={AddProfilePicture}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MurticarUserApplicationModal;

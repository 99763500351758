import { removePoliceUserToken, removeStudentTokenFromLocal } from "../../common";
import { policeTokenName, } from "../../config";

const getPoliceFromLocalStorage = () => {
  try {
    const police = JSON.parse(sessionStorage.getItem(policeTokenName));
    if (police && police.authToken && police.loggedInTime) {
      return police
    } else {
      removePoliceUserToken();
      return null
    }
  } catch (error) {
    removePoliceUserToken()
    return null
  }
}

const getInitial = () => {
  return getPoliceFromLocalStorage();
};

const PoliceUserReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_POLICE":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(policeTokenName, JSON.stringify(newState));
      return newState;
    case "LOGOUT_POLICE":
      removePoliceUserToken();
      return null;
    default:
      return state;
  }
};

export default PoliceUserReducers;
import React from "react";
import MurticarTotalApptions from "../../Administration/MurticarApptions/MurticarTotalApptions";

const SuperAdminMurtiTotalAppl = () => {
  return (
    <div>
      <MurticarTotalApptions role="Super Admin" />
    </div>
  );
};

export default SuperAdminMurtiTotalAppl;
